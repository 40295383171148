<template>
  <div>
    <div class="loading" v-if="isLoading">
      <div class="blackUI"></div>
      <div class="loadingText">{{ $primevue.config.language.loginMap.loading }}</div>
    </div>
    <div class="tooltip">
      <div class="iconDiv"></div>
      <img class="log" src="../../../assets/images/pageLogo.png">
      <div style="font-size: 24px;font-weight: 800">{{ $primevue.config.language.loginMap.loginPlatform }}</div>
      <div style="display:flex;align-items:center">
        <!--        <Button style="font-size: 18px;cursor: pointer;height: 35px" @click="choiceSystemLanguage">-->
        <!--          {{ $primevue.config.language.loginMap.systemLanguage }}-->
        <!--        </Button>&nbsp;&nbsp;-->
        <Button style="font-size: 18px;cursor: pointer;height: 35px" @click="$router.push('/login')">
          {{ $primevue.config.language.loginMap.loginButton }}
        </Button>
      </div>
    </div>
    <div class="content">
      <div id="mapNotLogin" style="width: 100%;height: 100%;"></div>
    </div>
    <Toast/>
    <!--    <Toast style="position:absolute;right:2rem;top:3rem;min-width:30rem;background:gray;background:yellow" />-->
    <Dialog header="提示" v-model:visible="display" @hide="display=false" :closable="false" :modal="true">
      {{ $primevue.config.language.loginMap.tips }}
      <template #footer>
        <button style="background:#fff;border:none;cursor:pointer" @click="download">
          {{ $primevue.config.language.loginMap.download }}
        </button>
      </template>
    </Dialog>
    <ChoiceLanguage v-if="choiceLanguageDisplay" ref="choicelanguage" @close="choiceLanguageDisplay=false"
                    @sysLang='sl'></ChoiceLanguage>
  </div>

</template>
<script>
import ChoiceLanguage from "./choiceLanguage.vue";
import languagePackages from '@/assets/language/languageType.js'

let map, win;
export default {
  name: "LoginMap",
  components: { ChoiceLanguage },
  data() {
    return {
      choiceLanguageDisplay: false,
      isEdg: false,
      display: false,
      data: null,
      isLoading: true,
      chatDisplay: false,
      languages: [],
      languageType: [],
    };
  },
  beforeUnmount() {
    win = null;
    if (map) {
      map.destroy();
      map = null;
    }
  },
  mounted() {
    // this.getLanguage();
    this.getPointList();
    if (this.$route.query.tokenOver) {
      this.$toast.add({
        severity: 'warn',
        summary: this.$primevue.config.language.loginMap.summary,
        detail: this.$primevue.config.language.loginMap.detail,
        life: 3000,
      });
    }
    if (this.$route.query.jurisdiction) {
      this.$toast.add({
        severity: 'warn',
        summary: this.$primevue.config.language.loginMap.summary,
        detail: this.$primevue.config.language.loginMap.QXdetail,
        life: 3000,
      });
    }
    this.getBrowserInfo();
  },
  methods: {
    sl(val) {
      this.isLoading = true
      this.languages.forEach((item, index) => {
        if (item.label == val) {
          this.$http(
            "i18n/getLanguagePackage",
            "get",
            { type: this.languageType[index] },
            (res) => {
              this.$primevue.config.language = res.data.data
              localStorage.setItem("language", JSON.stringify(res.data.data));
              this.isLoading = false
            }
          );
        }
      });
    },
    choiceSystemLanguage() {
      this.choiceLanguageDisplay = true;
      this.$nextTick(() => {
        this.$refs.choicelanguage.init(this.languages);
      });
    },
    systemLanguage() {
      if (!localStorage.getItem("language")) {
        console.log("触发首次加载系统语言");
        this.$http(
          "i18n/getLanguagePackage",
          "get",
          { type: "zh-cn" },
          (res) => {
            this.$primevue.config.language = res.data.data
            localStorage.setItem("language", JSON.stringify(res.data.data));
          }
        );
      }
    },
    getLanguage() {
      let type = ''
      if (localStorage.getItem("language")) {
        type = localStorage.getItem("language")
      } else {
        type = 'zh-cn'
        localStorage.setItem("language", 'zh-cn')
      }
      this.$primevue.config.language = languagePackages[type]
    },
    download() {
      if (this.isEdg) {
        window.location.href = "https://www.microsoft.com/zh-cn/edge";
      } else {
        window.location.href = "https://pc.qq.com/detail/1/detail_2661.html";
      }
    },
    confirm() {
      this.display = true;
    },
    getBrowserInfo() {
      let sysObj = {};
      let browserInfo = navigator.userAgent.toLowerCase();
      let vsMsg;
      (vsMsg = browserInfo.match(/msie ([\d.]+)/))
        ? (sysObj.ie = vsMsg[1])
        : (vsMsg = browserInfo.match(/firefox\/([\d.]+)/))
          ? (sysObj.firefox = vsMsg[1])
          : (vsMsg = browserInfo.match(/chrome\/([\d.]+)/))
            ? (sysObj.chrome = vsMsg[1])
            : (vsMsg = browserInfo.match(/opera.([\d.]+)/))
              ? (sysObj.opera = vsMsg[1])
              : (vsMsg = browserInfo.match(/version\/([\d.]+).*safari/))
                ? (sysObj.applewebkit = vsMsg[1])
                : (vsMsg = browserInfo.match(/applewebkit\/([\d.]+)/))
                  ? (sysObj.safari = vsMsg[1])
                  : 0;

      if (sysObj.ie) {
        this.confirm();
      }
      if (sysObj.firefox) {
        this.confirm();
      }
      if (sysObj.chrome) {
        let DEFAULT_VERSION = "100.0.0.0";
        this.isEdg = browserInfo.indexOf("edg") > -1;
        let chrome = browserInfo.indexOf("chrome") > -1;
        let safariVersion;
        let str;
        if (chrome) {
          let num = browserInfo.split(" ");
          num.forEach((item, index) => {
            if (item.indexOf("chrome") > -1) {
              str = num[index];
            }
          });
          safariVersion = str.substring(7);
        }
        if (safariVersion * 1 <= DEFAULT_VERSION * 1) {
          this.confirm();
        }
      }
      if (sysObj.opera) {
        this.confirm();
      }
      if (sysObj.safari) {
        this.display = false;
      }
    },

    init() {
      win = null;
      if (map) {
        map.destroy();
        map = null;
      }
      document.getElementById("mapNotLogin").innerHTML = "";
      let AMap = window["AMap"];
      let _this = this;
      let DistrictSearch = new AMap.DistrictSearch({
        level: "country",
        extensions: "all",
      });
      DistrictSearch.search(
        this.$primevue.config.language.loginMap.country,
        function(status, result) {
          map = new AMap.Map("mapNotLogin", {
            mapStyle: "amap://styles/706ebe0d9d517a7316e132d2790739e6", //设置地图的显示样式
            resizeEnable: true,
            center: [108.5525, 34.3227],
            layers: [
              new AMap.TileLayer.RoadNet(),
              new AMap.TileLayer.Satellite(),
            ],
            zoom: 5,
          });

          map.on("zoomend", function() {
            if (win) {
              map.remove(win);
              win = null;
            }
          });

          let ChinaBorder = Object.assign(
            [],
            result.districtList && result.districtList.length > 0 ? result.districtList[0].boundaries : []
          );
          let outer = [
            new AMap.LngLat(-360, 90, true),
            new AMap.LngLat(-360, -90, true),
            new AMap.LngLat(360, -90, true),
            new AMap.LngLat(360, 90, true),
          ];

          let pathArray = [outer];
          pathArray.push.apply(pathArray, ChinaBorder);

          let b = new AMap.Polygon({
            path: pathArray,
            strokeWeight: 2,
            strokeColor: "#111111",
            fillColor: "#111111",
            fillOpacity: 0.6,
          });

          //给中国加遮罩
          map.add(b);

          let markers = [];
          _this.data.forEach((item) => {
            markers.push(_this.createMarker(JSON.parse(JSON.stringify(item))));
          });

          let count = markers.length;
          new AMap.MarkerClusterer(map, markers, {
            gridSize: 80,
            maxZoom: 16,
            renderClusterMarker: function(context) {
              // var factor = Math.pow(context.count / count, 1 / 18);
              let div = document.createElement("div");
              // var Hue = 180 - factor * 180;
              div.style.backgroundColor = "rgba(0, 130, 117, 1)";
              let size = Math.round(
                30 + Math.pow(context.count / count, 1 / 5) * 20
              );
              div.style.width = div.style.height = size + "px";
              div.style.border = "solid 4px #fff";
              div.style.borderRadius = size / 2 + "px";
              div.style.boxShadow = "1px 3px 16px 0px rgba(23, 54, 73, 0.3)";
              div.innerHTML = context.count;
              div.style.lineHeight = size - 6 + "px";
              div.style.color = "#fff";
              div.style.fontSize = "24px";
              div.style.textAlign = "center";
              context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
              context.marker.setContent(div);
            },
          });

          _this.isLoading = false;
        }
      );
    },
    getPointList() {
      this.isLoading = true;
      this.$http("/projectShow/getList", "get", "", (res) => {
        if (res.data) {
          this.data = res.data;
          this.init();
        }
      });
    },

    createContent(item) {
      const _this = this;
      let content = document.createElement("div");
      content.className = "iconBlock";
      let iconNode = document.createElement("i");
      iconNode.className = "iconfont icon-xiangmu1";
      iconNode.style.fontSize = "16px";
      iconNode.style.color = "white";
      if (!item.imgName) {
        content.style.backgroundColor = "rgba(18, 162, 93, 1)";
      } else {
        content.style.backgroundColor = "rgba(255, 205, 66, 1)";
      }

      content.append(iconNode);
      content.onclick = function() {
        if (win) {
          map.remove(win);
          win = null;
        } else {
          let window = document.createElement("div");
          window.className = "myDialog";
          if (item.title) {
            let title = document.createElement("div");
            title.className = "myDialogTitle";
            title.innerText = item.title;
            let link = document.createElement("a");
            link.href = item.link;
            link.innerText = this.$primevue.config.language.loginMap.link;
            link.target = "_blank";
            link.style.color = "rgba(18, 162, 93, 1)";
            title.appendChild(link);

            window.appendChild(title);
          }
          if (item.imgName) {
            let img = document.createElement("img");
            img.className = "DialogImg";
            img.src = _this.$minioUrl + item.imgName;
            window.appendChild(img);
          }

          let address = document.createElement("div");
          address.className = "DialogAddress";

          if (item.content) {
            let content = document.createElement("div");
            content.innerText = item.content;
            content.className = "dialogContent";
            address.appendChild(content);
          }

          let addressText = document.createElement("span");
          let addressIconNode = document.createElement("i");
          addressIconNode.className = "pi pi-map-marker p-mr-2";
          addressIconNode.style.color = "rgba(18, 162, 93, 1)";
          addressText.appendChild(addressIconNode);
          let text = document.createElement("span");
          text.innerText =
            (item.country ? item.country : "") +
            (item.province ? item.province : "") +
            (item.city ? item.city : "") +
            (item.district ? item.district : "");
          addressText.appendChild(text);

          if (item.park) {
            let park = document.createElement("span");
            let parkIcon = document.createElement("i");
            parkIcon.className = "pi pi-folder p-mr-2";
            parkIcon.style.color = "rgba(18, 162, 93, 1)";
            let parkTex = document.createElement("span");
            parkTex.innerText = item.park;
            park.appendChild(parkIcon);
            park.appendChild(parkTex);
            address.appendChild(park);
          }
          if (item.imgName) {
            window.style.width = "600px";
            address.style.position = "absolute";
            address.style.width = "100%";
            address.style.removeProperty("borderRadius");
          } else {
            window.style.width = "auto";
            address.style.position = "relative";
            address.style.borderRadius = "5px";
          }
          address.appendChild(addressText);
          window.appendChild(address);

          win = null;
          win = new AMap.Marker({
            position: [item.lon, item.lat],
            content: window,
            offset: new AMap.Pixel(-20, 25),
          });
          map.add(win);
        }
      };
      return content;
    },
    createMarker(item) {
      let content = this.createContent(item);
      let marker = new AMap.Marker({
        position: [item.lon, item.lat],
        content: content,
        offset: new AMap.Pixel(-15, -15),
      });
      return marker;
    },
  },
};
</script>
<style>
.DialogAddress span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialogContent {
  width: 100%;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 15px;
}

.DialogAddress {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: white;
  z-index: 999;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px;
}

.DialogImg {
  width: 100%;
  height: auto;
  min-height: 108px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.myDialog {
  width: 600px;
  height: auto;
  min-height: 30px;
  background: white;
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.myDialogTitle {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
}

.myDialog:before {
  content: "";
  position: absolute;
  top: -20px;
  left: 20px;
  width: 0;
  height: 0;
  box-sizing: border-box;
  border-style: solid;
  border-width: 10px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: white;
}

.iconBlock {
  background: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 30px;
  border: 3px solid white;
}
</style>
<style scoped>
.tooltip {
  width: 100%;
  height: 50px;
  background-image: linear-gradient(
    to right,
    rgba(58, 144, 75, 1),
    rgba(58, 144, 75, 0.01)
  );
  z-index: 1000;
  padding-left: 200px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  align-items: center;
}

.tooltip div {
  height: 100%;
  line-height: 50px;
  color: #ffffff;
}

.iconDiv {
  background: #173649;
  width: 130px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
}

.log {
  height: 30px;
  z-index: 200;
  position: absolute;
  left: 25px;
  top: 10px;
}

.iconDiv::after {
  content: "";
  width: 40px;
  height: 75px;
  transform: rotate(30deg);
  background: #173649;
  position: absolute;
  right: -25px;
  top: -25px;
}

.content {
  width: 100%;
  height: calc(100vh - 50px);
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}

.blackUI {
  width: 100%;
  height: 100%;
  background: #111111;
  opacity: 0.5;
}

.loadingText {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  position: absolute;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  top: calc(50% - 25px);
  left: 0;
}

ConfirmDialog .p-dialog .p-dialog-header-icons {
  display: none;
}

/* .p-cascadeselect:not(.p-disabled) .p-focus {
  box-shadow: 0 0 0 0.2rem rgba(18, 162, 93, 1) !important;
  border-color: rgba(18, 162, 93, 1) !important;
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: rgba(18, 162, 93, 1) !important;
} */
</style>
